import { FC } from "react";
import { useLocale } from "@/i18n/i18n-client";
import { SectionHeadingProps } from "../../types";
import styled from "styled-components";

const SectionHeading: FC<SectionHeadingProps> = ({ section }) => {
  const { code: activeLocale } = useLocale();
  const translations = section?.translations?.find(
    (translation) => translation.locale === activeLocale
  );

  return section?.showSectionHeader ? (
    <SectionHeader>{translations?.header || section?.header}</SectionHeader>
  ) : null;
};

const SectionHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-inline-start: 10px;
`;

export default SectionHeading;
