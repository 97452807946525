import ProductCardRenderer from "@/Renderers/ProductCardRenderer";
import SectionCardWrapper from "@/templates/black-and-white/elements/SectionCardWrapper";
import { FormattedDataType } from "@/templates/types";
import React from "react";
import ProductCard from "./ProductCard";

interface RenderProductCardProps {
  product: FormattedDataType["products"][0];
  index: number;
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
}

const RenderProductCard: React.FC<RenderProductCardProps> = ({
  product,
  index,
  section,
  isSliderSection,
}) => {
  return (
    <SectionCardWrapper
      isSlider={isSliderSection}
      isProduct
      data-test="collection-card"
      background={section?.background}
    >
      <ProductCardRenderer
        Component={ProductCard}
        product={product}
        isSlider={isSliderSection}
        productPerRow={section.itemsPerRow}
        index={index}
        productsCount={section?.products?.length}
      />
    </SectionCardWrapper>
  );
};

export default RenderProductCard;
