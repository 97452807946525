import SectionCardWrapper from "@/templates/black-and-white/elements/SectionCardWrapper";
import { FormattedDataType } from "@/templates/types";
import React from "react";
import AttributeValueCard from "./AttributeValueCard";
import { sectionAspectRatio } from "@/shared/utils/aspect-ratio";

interface RenderAttributeCardProps {
  attributeValue: FormattedDataType["attributeValues"][0];
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
  isOneElement?: boolean;
}

const RenderAttributeCard: React.FC<RenderAttributeCardProps> = ({
  attributeValue,
  section,
  isSliderSection,
  isOneElement,
}) => {
  return (
    <SectionCardWrapper
      isSlider={isSliderSection}
      data-test="attributeValue-card"
      background={section?.background}
      aspectRatio={sectionAspectRatio(attributeValue.image, isOneElement)}
      maxHeight={300}
    >
      <AttributeValueCard
        attributeValue={attributeValue}
        hideTitle={!section?.showItemName}
      />
    </SectionCardWrapper>
  );
};

export default RenderAttributeCard;
