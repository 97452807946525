import React from "react";
import styled from "styled-components";
import { Photo } from "@/shared/globals/UiElements/Photo";
import { AttributeValueCardProps } from "../../../../types";
import { Link } from "@/i18n/i18n-navigation";

const AttributeValueCard: React.FC<AttributeValueCardProps> = ({
  attributeValue,
  hideTitle,
}) => {
  return (
    <StyledLink
      fullHeight
      href={{
        href: `/shop`,
        query: {
          page: 1,
          filters: `a:${attributeValue?.id}|${attributeValue?.attribute?.id}`,
        },
      }}
    >
      <StyledPhoto
        src={attributeValue?.image?.src || "/default-placeholder-image.png"}
        alt={attributeValue?.name!}
      />
      {!hideTitle && (
        <AttributeValueTitle>{attributeValue?.name}</AttributeValueTitle>
      )}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px;
`;

const StyledPhoto = styled(Photo)`
  max-height: 300px;
  object-fit: contain;
`;

const AttributeValueTitle = styled.div`
  text-align: center;
  word-wrap: break-word;
`;

export default AttributeValueCard;
