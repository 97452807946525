import React, { useState } from "react";
import styled from "styled-components";
import { Swiper } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Section } from "@/shared/layout";
import type { HomeGallerySectionProps } from "../../types";
import { rtl } from "@/shared/styles-utils";
import { ArrowRight } from "@/assets/Icons/ArrowRight";
import { FlexRow } from "@/shared/globals";
import useBreakPoints from "@/shared/utils/useBreakPoints";
import { DisplayAsEnum, SectionTypeEnum } from "@/generated/graphql";
import SectionGenerator from "../components/HomeGallerySection/SectionGenerator";
import { ZIndex } from "@/shared/globals/types";
import CollectionRowContainer from "../components/HomeGallerySection/CollectionRowContainer";
import { getColumnCount } from "@/shared/utils/homeHelpers";
import SectionHeading from "./SectionHeading";
import GridGallerySection from "./GridGallerySection";
import ReactSwiperCss from "@/shared/globals/LazyElements/react-swiper";

const HomeGallerySection: React.FC<HomeGallerySectionProps> = ({ section }) => {
  const { isTablet } = useBreakPoints();
  const [prevEl, setPrevEl] = useState<HTMLSpanElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLSpanElement | null>(null);
  const { columnCount } = getColumnCount(section);
  const slidesPerView = isTablet ? 1 : section.itemsPerRow;

  return (
    <StyledSection
      stretchToFullWidth={section?.stretchToFullWidth}
      isCollections={section?.type === SectionTypeEnum.CollectionsRow}
    >
      <SectionHeading section={section} />
      {section.displayAs === DisplayAsEnum.Slider ? (
        <SliderContainer spacing="none">
          <SlideButtons
            isProduct={section?.type === SectionTypeEnum.ProductsRow}
            ref={(node) => setPrevEl(node)}
          >
            <ArrowRight />
          </SlideButtons>
          <ReactSwiperCss />
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={20}
            navigation={{ prevEl, nextEl }}
            modules={[Navigation, Autoplay]}
            loop={isTablet || columnCount >= section.itemsPerRow!}
            style={{
              width: "100%",
            }}
            autoplay={{
              delay: 4000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
          >
            {SectionGenerator({ section, isSliderSection: true })}
          </Swiper>

          <SlideButtons
            isProduct={section?.type === SectionTypeEnum.ProductsRow}
            right
            ref={(node) => setNextEl(node)}
          >
            <ArrowRight />
          </SlideButtons>
        </SliderContainer>
      ) : section?.type === SectionTypeEnum.CollectionsRow ? (
        <CollectionRowContainer itemsPerRow={section.itemsPerRow}>
          <SectionGenerator section={section} />
        </CollectionRowContainer>
      ) : (
        <>
          <GridGallerySection
            itemsPerRow={section.itemsPerRow}
            isProduct={section?.type === SectionTypeEnum.ProductsRow}
          >
            <SectionGenerator section={section} />
          </GridGallerySection>
        </>
      )}
    </StyledSection>
  );
};

export default HomeGallerySection;

/**
 *
 * Styles
 *
 */

const StyledSection = styled(Section)<{
  stretchToFullWidth?: boolean;
  isCollections?: boolean;
}>`
  && {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    max-width: ${({ stretchToFullWidth }) => stretchToFullWidth && "100vw"};
    padding: ${({ stretchToFullWidth }) => stretchToFullWidth && "unset"};
    justify-content: ${({ isCollections }) => isCollections && "center"};
  }
`;

const SliderContainer = styled(FlexRow)`
  position: relative;
`;

const SlideButtons = styled.span<{ right?: boolean; isProduct: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ right }) => (right ? "flex-end" : "flex-start")};
  width: ${({ isProduct }) => (isProduct ? "auto" : "85px")};
  height: ${({ isProduct }) => (isProduct ? "auto" : "100%")};
  z-index: ${ZIndex["TOOLTIP"]};
  cursor: pointer;
  left: ${({ right }) => (right ? rtl(0, "unset") : rtl("unset", 0))};
  right: ${({ right }) => (right ? rtl("unset", 0) : rtl(0, "unset"))};

  position: absolute;

  svg {
    transform: ${({ right }) =>
      right ? rtl("rotate(180deg)", "") : rtl("", "rotate(180deg)")};
    color: #000;
    width: 32px;
    border: 1px solid #ebe9e9;
    height: 32px;
    padding: 10px;
    border-radius: 50%;
    background: #fff;
  }
`;
