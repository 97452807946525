import React from "react";
import styled from "styled-components";

const CollectionRowContainer = ({ children, itemsPerRow }) => {
  return <Container itemsPerRow={itemsPerRow}>{children}</Container>;
};

const Container = styled.div<{
  itemsPerRow?: number;
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  gap: 10px;

  max-width: ${({ itemsPerRow }) =>
    // 125 collection card width + 10px padding*2 + 10px gap between cards * columnCount
    `calc(155 * ${itemsPerRow}px)`};
`;

export default CollectionRowContainer;
